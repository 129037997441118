import FILTER_ALL_VALUE from 'src/constants/contexts/filterAllValue';
import PRODUCT_TYPES from 'src/constants/productTypes';
import i18n from 'src/locales/i18n';

// Constants for filter options
const COMPANY_QUALIFICATION_TYPE_STATUSES = {
  ALL: FILTER_ALL_VALUE,
  EMAIL: 'EMAIL',
  ONBOARDING: 'ONBOARDING',
  REFUSED: 'REFUSED',
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  ANALYSIS: 'ANALYSIS',
  CUSTOMER: 'CUSTOMER',
};

const COMPANY_QUALIFICATION_TYPE_LABELS = {
  [COMPANY_QUALIFICATION_TYPE_STATUSES.ALL]: i18n.t('commons.companyQualificationTypeLabels.all'),
  [COMPANY_QUALIFICATION_TYPE_STATUSES.EMAIL]: i18n.t(
    'commons.companyQualificationTypeLabels.email'
  ),
  [COMPANY_QUALIFICATION_TYPE_STATUSES.ONBOARDING]: i18n.t(
    'commons.companyQualificationTypeLabels.onboarding'
  ),
  [COMPANY_QUALIFICATION_TYPE_STATUSES.ANALYSIS]: i18n.t(
    'commons.companyQualificationTypeLabels.analysis'
  ),
  [COMPANY_QUALIFICATION_TYPE_STATUSES.CUSTOMER]: i18n.t(
    'commons.companyQualificationTypeLabels.customer'
  ),
  [COMPANY_QUALIFICATION_TYPE_STATUSES.REFUSED]: i18n.t(
    'commons.companyQualificationTypeLabels.refused'
  ),
};

const COMPANY_STATUSES_FILTER_OPTIONS = [
  {
    label: i18n.t('commons.companyQualificationTypeLabels.all'),
    value: COMPANY_QUALIFICATION_TYPE_STATUSES.ALL,
  },
  {
    label: i18n.t('commons.companyQualificationTypeLabels.email'),
    value: COMPANY_QUALIFICATION_TYPE_STATUSES.EMAIL,
  },
  {
    label: i18n.t('commons.companyQualificationTypeLabels.onboarding'),
    value: COMPANY_QUALIFICATION_TYPE_STATUSES.ONBOARDING,
  },
  {
    label: i18n.t('commons.companyQualificationTypeLabels.analysis'),
    value: COMPANY_QUALIFICATION_TYPE_STATUSES.ANALYSIS,
  },
  {
    label: i18n.t('commons.companyQualificationTypeLabels.customer'),
    value: COMPANY_QUALIFICATION_TYPE_STATUSES.CUSTOMER,
  },
  {
    label: i18n.t('commons.companyQualificationTypeLabels.refused'),
    value: COMPANY_QUALIFICATION_TYPE_STATUSES.REFUSED,
  },
];

const COMPANY_STATUS_FILTER_OPTIONS = [
  {
    label: i18n.t('commons.companyStatuses.all'),
    value: FILTER_ALL_VALUE,
  },
  {
    label: i18n.t('commons.companyStatuses.active'),
    value: false,
  },
  {
    label: i18n.t('commons.companyStatuses.default'),
    value: true,
  },
];

const COMPANY_PRODUCT_TYPES = {
  ALL: FILTER_ALL_VALUE,
  ...Object.keys(PRODUCT_TYPES).reduce((acc, key) => {
    acc[key] = key;
    return acc;
  }, {}),
};

const COMPANY_PRODUCT_TYPE_FILTER_OPTIONS = [
  {
    label: i18n.t('commons.companyProductTypes.all'),
    value: COMPANY_PRODUCT_TYPES.ALL,
  },
  {
    label: i18n.t('commons.companyProductTypes.saas'),
    value: COMPANY_PRODUCT_TYPES.SAAS,
  },
  {
    label: i18n.t('commons.companyProductTypes.recurringRevenue'),
    value: COMPANY_PRODUCT_TYPES.RECURRING_REVENUE,
  },
  {
    label: i18n.t('commons.companyProductTypes.ecommerce'),
    value: COMPANY_PRODUCT_TYPES.ECOMMERCE,
  },
  {
    label: i18n.t('commons.companyProductTypes.marketplace'),
    value: COMPANY_PRODUCT_TYPES.MARKETPLACE,
  },
  {
    label: i18n.t('commons.companyProductTypes.agency'),
    value: COMPANY_PRODUCT_TYPES.AGENCY,
  },
];

const COMPANY_MONITORING_TYPES = {
  ALL: FILTER_ALL_VALUE,
  NO: 'NO',
  YES: 'YES',
};

const COMPANY_MONITORING_TYPE_FILTER_OPTIONS = [
  {
    label: i18n.t('commons.companyMonitoringTypes.all'),
    value: COMPANY_MONITORING_TYPES.ALL,
  },
  {
    label: i18n.t('commons.companyMonitoringTypes.yes'),
    value: true,
  },
  {
    label: i18n.t('commons.companyMonitoringTypes.no'),
    value: false,
  },
];

// Constants for filter keys
const ANALYTICS_STATUS_FILTER = 'analyticsStatusFilter';
const COMPANY_STATUS_FILTER = 'companyStatusFilter';
const MONITORING_FILTER = 'monitoringFilter';
const PRODUCT_TYPE_FILTER = 'productTypeFilter';

// Constants for filter initial values
const INITIAL_ANALYTICS_STATUS_FILTER = COMPANY_QUALIFICATION_TYPE_STATUSES.ALL;
const INITIAL_COMPANY_STATUS_FILTER = COMPANY_STATUS_FILTER_OPTIONS[0].value;
const INITIAL_MONITORING_FILTER = COMPANY_MONITORING_TYPES.ALL;
const INITIAL_PRODUCT_TYPE_FILTER = COMPANY_PRODUCT_TYPES.ALL;

const contextValues = [
  {
    constant: ANALYTICS_STATUS_FILTER,
    initialValue: INITIAL_ANALYTICS_STATUS_FILTER,
  },
  {
    constant: COMPANY_STATUS_FILTER,
    initialValue: INITIAL_COMPANY_STATUS_FILTER,
  },
  {
    constant: MONITORING_FILTER,
    initialValue: INITIAL_MONITORING_FILTER,
  },
  {
    constant: PRODUCT_TYPE_FILTER,
    initialValue: INITIAL_PRODUCT_TYPE_FILTER,
  },
];

const companiesFiltersConstants = {
  contextValues,
  filtersConstants: {
    ANALYTICS_STATUS_FILTER,
    COMPANY_QUALIFICATION_TYPE_LABELS,
    COMPANY_QUALIFICATION_TYPE_STATUSES,
    COMPANY_STATUS_FILTER,
    MONITORING_FILTER,
    PRODUCT_TYPE_FILTER,
  },
  filtersOptions: {
    COMPANY_MONITORING_TYPE_FILTER_OPTIONS,
    COMPANY_PRODUCT_TYPE_FILTER_OPTIONS,
    COMPANY_STATUSES_FILTER_OPTIONS,
    COMPANY_STATUS_FILTER_OPTIONS,
  },
  initialsConstants: {
    INITIAL_ANALYTICS_STATUS_FILTER,
    INITIAL_COMPANY_STATUS_FILTER,
    INITIAL_MONITORING_FILTER,
    INITIAL_PRODUCT_TYPE_FILTER,
  },
};

export default companiesFiltersConstants;
