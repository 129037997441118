import { gql } from '@apollo/client';

const fileFragment = gql`
  fragment File on File {
    id
    fileName
    displayName
    fileType
    isOutdated
    isValid
    isDeletable
    createdAt
    updatedAt
    filesCount
    isFolder
    isPrivate
    metadata {
      subtitle
    }
  }
`;

export default fileFragment;
