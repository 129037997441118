import { Stack, Typography } from '@mui/material';
import React from 'react';
import { RHFSelect } from 'src/components/hook-form';
import PRODUCT_TYPES from 'src/constants/productTypes';
import companiesFiltersConstants from 'src/contexts/companies/companiesFiltersConstants';
import { UPDATE_COMPANY } from 'src/graphql/companies/mutations';
import useDialogs from 'src/hooks/useDialogs';
import { useSafeMutation } from 'src/services/apollo-client/wrappers';

const CompanySectorsSelects = ({ businessAreas, company, translate }) => {
  const { confirm } = useDialogs();

  const [updateCompany] = useSafeMutation(UPDATE_COMPANY);

  const handleSelectChange = async (name, value) => {
    if (await confirm()) {
      try {
        await updateCompany({
          variables: {
            companyId: company.id,
            input: {
              [name]: value,
            },
          },
        });
      } catch {
        // Nothing to do
      }
    }
  };

  return (
    <Stack spacing={4}>
      <Stack>
        <Typography variant="h6">
          {translate(
            'sections.dashboardAdministrationCompaniesV2DetailsCompanySectorsSelectsTitle'
          )}
        </Typography>
        <Typography
          color="text.secondary"
          sx={{ fontStyle: 'italic', fontWeight: 'bold' }}
          variant="body2"
        >
          {translate(
            'sections.dashboardAdministrationCompaniesV2DetailsCompanySectorsSelectsSubtitle'
          )}
        </Typography>
      </Stack>
      <Stack spacing={4}>
        <RHFSelect
          InputLabelProps={{ shrink: true }}
          label={translate('sections.authRegisterCompanyFormBusinessAreaLabel')}
          name="businessAreaId"
          onClick={(e) => handleSelectChange('businessAreaId', e.target.dataset.value)}
          options={businessAreas.map((businessArea) => ({
            label: businessArea.name,
            value: businessArea.id,
          }))}
          value={company.businessArea.id}
        />
        <RHFSelect
          InputLabelProps={{ shrink: true }}
          label={translate('sections.authRegisterCompanyFormMainProductLabel')}
          name="mainProduct"
          onClick={(e) => handleSelectChange('mainProduct', e.target.dataset.value)}
          options={Object.keys(PRODUCT_TYPES).map((key) => ({
            label: translate(`commons.${PRODUCT_TYPES[key]}`),
            value: PRODUCT_TYPES[key],
          }))}
          value={company.mainProduct}
        />
        <RHFSelect
          InputLabelProps={{ shrink: true }}
          label="Qualification de l'entreprise"
          name="status"
          onClick={(e) => handleSelectChange('status', e.target.dataset.value)}
          options={companiesFiltersConstants.filtersOptions.COMPANY_STATUSES_FILTER_OPTIONS.map(
            (option) => ({
              label: translate(option.label),
              value: option.value,
            })
          ).filter((option) => option.value !== 'ALL')}
          value={company.status}
        />
      </Stack>
    </Stack>
  );
};

export default CompanySectorsSelects;
