import { Box, Drawer, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormProvider } from 'src/components/hook-form';
import SafeQueryResults from 'src/components/SafeQueryResults.js';
import { LIST_BUSINESS_AREAS } from 'src/graphql/business-areas/queries';
import { GET_COMPANY_BY_ID } from 'src/graphql/companies/queries';
import useResponsive from 'src/hooks/useResponsive';
import { useSafeLazyQuery } from 'src/services/apollo-client/wrappers';

import CompanyDetailsDrawerSkeleton from './CompanyDetailsDrawerSkeleton';
import CompanyHeader from './components/CompanyHeader';
import CompanyInformations from './components/CompanyInformations';
import CompanyDrawerTab from './components/tabs/CompanyDrawerTab';

const handleDrawerContentClick = (event) => {
  event.stopPropagation();
};

const CompanyDrawerContent = ({ businessAreas, company }) => {
  const defaultValues = {
    businessAreaId: company.businessArea.id,
    mainProduct: company.mainProduct,
    status: company.status,
  };

  const methods = useForm({
    defaultValues,
  });
  return (
    <Box display="flex" flexDirection="column" height="100%" onClick={handleDrawerContentClick}>
      <Stack flexGrow={1}>
        <FormProvider methods={methods} noValidate>
          <CompanyHeader company={company} />
          <CompanyInformations company={company} />
          <CompanyDrawerTab businessAreas={businessAreas} company={company} />
        </FormProvider>
      </Stack>
    </Box>
  );
};

const CompanyDetailsDrawer = ({ companyId, onClose, open }) => {
  const isMobile = useResponsive('down', 'sm');

  const [getCompanyById, { data: currentCompanyData }] = useSafeLazyQuery(GET_COMPANY_BY_ID, {
    variables: {
      companyId,
    },
  });

  const [getListBusinessAreas, { data: listBusinessAreasData }] = useSafeLazyQuery(
    LIST_BUSINESS_AREAS,
    {
      variables: {
        page: 1,
        rowsPerPage: 100,
      },
    }
  );

  const hasResults = currentCompanyData && listBusinessAreasData;
  useEffect(() => {
    if (open) {
      getCompanyById();
      getListBusinessAreas();
    }
  }, [open, getCompanyById, getListBusinessAreas]);

  return (
    <Drawer
      anchor="right"
      BackdropProps={{ invisible: true }}
      full
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: isMobile ? '100%' : 480 } }}
    >
      <SafeQueryResults hasResults={hasResults} LoaderComponent={<CompanyDetailsDrawerSkeleton />}>
        {() => {
          const { getCompany } = currentCompanyData;
          const { listBusinessAreas } = listBusinessAreasData;

          return (
            <CompanyDrawerContent
              businessAreas={listBusinessAreas.items}
              company={getCompany}
              onCLose={onClose}
            />
          );
        }}
      </SafeQueryResults>
    </Drawer>
  );
};

export default CompanyDetailsDrawer;
